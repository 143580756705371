import React from "react";
import Footer from "src/components/Footer";
import Navbar from "src/components/Navbar";

function Privacy() {
    return (
        <>
            <Navbar type="full" />
            <div className="termsPage">
                <h3 className="mb-4">Last Updated: 17th August 2022</h3>

                <p>Beba Mzigo Limited and its subsidiaries (“Beba Mzigo”, “we” or “us”) respect your privacy and are committed to protecting your personal data. This Privacy Policy informs you on how we collect, use, share and protect your personal data and tells you about your privacy rights and how the law protects you.</p>

                <h2>IMPORTANT INFORMATION AND WHO WE ARE</h2>
                <p>This Privacy Policy covers collection of your personal data where you access any of the services offered by Beba Mzigo <b>(Services)</b> either online (for example through our website or our mobile apps) or where we collect your personal data offline (for example collection of data through email, physical forms, telephone, or in person).</p>

                <p><b>By using our Services or by otherwise sharing with us your personal data, you agree to the terms of this Privacy Policy.</b> Please review it carefully to understand our privacy practices. If you have questions about this Policy, please contact our team at <a href="#">privacy@bebamzigo com.</a></p>

                <p><b>Data controller:</b> Depending on the Service that you are accessing or using, the data controller will be the Beba Mzigo company that you have entered into a contract with or are seeking to enter into a contract with, or, that you have otherwise provided your personal data to, and such company will be responsible for your personal data. In this Policy, the contracting Beba Mzigo subsidiary and Beba Mzigo’s group of companies are collectively referred to as “Beba Mzigo”, <b>"we"</b> or <b>“us”</b>.</p>

                <p><b>Our contact details:</b> If you have any questions about this Privacy Policy or our privacy practices, please contact us at <a href="#">privacy@bebamzigo.com.</a></p>

                <p><b>Changes to the Privacy Policy and your duty to inform us of changes:</b> We keep our Privacy Policy under regular review. When we make material changes to this Privacy Policy, we will notify you by posting the updated policy on our website and Beba Mzigo’s mobile apps, and we will update the effective date at the top of this policy. By continuing to use any of our Services or accessing our website and mobile apps after being notified of material changes to this policy, you agree to the terms of the updated policy.</p>
                <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
                <p><b>Third-party links:</b> Our website and mobile apps may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website or mobile apps, we encourage you to read the Privacy Policy of every website you visit.</p>
                <p><b>Children Data:</b> The Services are not intended for children and we do not knowingly collect data relating to children with or without parental consent.</p>

                <h2>THE DATA WE COLLECT ABOUT YOU</h2>
                <p>Personal data means any information relating to an identified or identifiable natural person. It does not include data where the identity has been removed (anonymous data).</p>

                <p>We may collect, use, store, process and transfer different kinds of personal data about you which we have grouped together as follows:</p>
                <ul>
                    <li><b>Identity Data</b> which includes your first name, maiden name, last name, username or similar identifier, username on social media platforms or other online platforms, KRA PIN, Identity Card details, title, date of birth.</li>
                    <li><b>Contact Data</b> which includes billing address, delivery address, email address, telephone numbers, and digital contact details.</li>
                    <li><b>Financial Data</b> which includes bank account, payment card, mobile payments, and other financial related details, including details on creditworthiness.</li>
                    <li><b>Transaction Data</b> which includes details about payments to and from you and other details of products and services you have purchased from us.</li>
                    <li><b>Technical Data</b> which includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website and mobile apps. When you use any of our mobile apps, they may automatically collect and store some or all of the following information from your mobile device, including without limitation: your preferred language; your phone number or other unique device identifier assigned to your mobile device; the IP address of your mobile device; the manufacturer and model of your mobile device; your mobile operating system; the type of mobile Internet browsers you are using; your geolocation; information about how you interact with the mobile apps, such as how many times you use a specific part of the mobile app over a given time period, the amount of time you spend using the mobile app, how often you use the mobile app, actions you take in the mobile app and how you engage with the mobile app.</li>
                    <li><b>Profile Data</b> which includes your username and password, purchases or orders made by you, services you have accessed, your interests, preferences, feedback and survey responses, and any information that tells us your behaviour as a customer or user of our Services.</li>
                    <li><b>Usage Data</b> which includes information about how you use our mobile apps, website, products and services.</li>
                    <li><b>Marketing and Communications Data</b> which includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
                    <li><b>Location Data</b> which includes details of the location where you access our Services from or location details that you provide to us.</li>
                </ul>

                <p>This list is not exhaustive but only serves to give examples of the types of data that we collect.</p>
                <p>We also collect personal data of other data subjects that you provide. You confirm and warrant that you have obtained the necessary consents and are lawfully permitted to provide such personal data to us.</p>

                <p>We also collect, use and share <b>Aggregated Data</b> such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website or mobile app feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Privacy Policy.</p>

                <p>We do not ordinarily collect any <b>Sensitive Personal Data</b> about you (this includes details about your race, health status, ethnic social origin, conscience, belief, genetic data, biometric data, real property details, marital status, family details including names of the person's children, parents, spouse or spouses, sex or the sexual orientation).Where we collect your Sensitive Personal Data (in connection with the provision of medical benefits), such data is accorded a high degree of protection in line with applicable data protection laws.</p>

                <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</p>

                <h2>HOW IS YOUR PERSONAL DATA COLLECTED</h2>
                <p>We use different methods to collect data from and about you including through:</p>
                <p><b>Direct interactions:</b> You may give us your Identity, Contact and Financial Data by filling in forms (online or offline) or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:</p>
                <ul>
                    <li>apply for our Services;</li>
                    <li>create an account on our website/mobile apps;</li>
                    <li>subscribe to our any of our Services or publications;</li>
                    <li>request marketing information to be sent to you;</li>
                    <li>enter a competition, promotion or survey; or</li>
                    <li>give us feedback or contact us.</li>
                </ul>

                <p><b>Automated technologies or interactions.</b> As you interact with our website and mobile apps, we will automatically collect data including Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs, embedded scripts and other similar technologies.</p>
                <p><b>Third parties or publicly available sources.</b> We will receive personal data about you from various third parties and public sources as set out below:</p>
                <ul>
                    <li>Technical Data from analytics providers; advertising networks; and search information providers.</li>
                    <li>Identity, Contact, Financial and Transaction Data from providers of technical, verification, payment and delivery services.</li>
                    <li>Identity and Contact Data from data brokers or aggregators.</li>
                    <li>Identity and Contact Data from publicly available sources.</li>
                </ul>

                <h2>HOW WE USE YOUR PERSONAL DATA</h2>
                <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
                <ol>
                    <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
                    <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
                    <li>Where we need to comply with a legal obligation.</li>
                </ol>
                <p>We will get your consent before sending direct marketing communications to you via email or text message or rely on consent given in the agreement you have with us. You have the right to withdraw consent to marketing at any time by contacting us.</p>
                <p><b>Purposes for which we will use your personal data.</b> We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal basis we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>
                <p>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data.</p>

                <p className="mb-1"><b>Purpose/Activity</b></p>
                <p className="mb-1"><b>Purpose/Activity</b></p>
                <p className="mb-1"><b>Lawful basis for processing including basis of legitimate interest</b></p>

                <p>To register you as a new user of our Services</p>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Performance of a contract with you</li>
                </ul>

                <p>To register you as a new user of our Services</p>
                <ul>
                    <li>Manage payments, fees and charges</li>
                    <li>Collect and recover money owed to us</li>
                    <li>Determine the amount we will charge you for our Services</li>
                    <li>Determine the location where you will receive our Services or where you require our Services</li>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Financial</li>
                    <li>Transaction</li>
                    <li>Marketing and communications</li>
                    <li>Location</li>
                    <li>Performance of a contract with you</li>
                </ul>

                <p>To manage our relationship with you which will include:</p>
                <ul>
                    <li>Communicating with you in the course of providing our Services</li>
                    <li>Notifying you about changes to our terms or privacy policy</li>
                    <li>Asking you to leave a review or take a survey</li>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile</li>
                    <li>Marketing and Communications</li>
                    <li>Performance of a contract with you</li>
                    <li>Necessary to comply with a legal obligation</li>
                    <li>Necessary for our legitimate interests (such as to keep our records updated and to study how customers use our products/services)</li>
                </ul>

                <p>To enable you to partake in a prize draw, competition or complete a survey</p>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>Marketing and Communications</li>
                    <li>Performance of a contract with you</li>
                    <li>Necessary for our legitimate interests (such as to study how customers use our products/services, to develop them and grow our business)</li>
                </ul>

                <p>To administer, grow and protect our business, our website and mobile apps (including, without limitation, troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data, verification, research and development)</p>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Technical</li>
                    <li>Transaction</li>
                    <li>Financial</li>
                    <li>Usage</li>
                    <li>Necessary for our legitimate interests (such as for running our business, for research and development, provision of administration and IT services, network security, to prevent fraud, and in the context of a business reorganisation or group restructuring exercise)</li>
                    <li>Necessary to comply with a legal obligation</li>
                </ul>

                <p>To deliver relevant website and mobile app content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</p>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>Marketing and Communications</li>
                    <li>Technical</li>
                    <li>Your consent given to us permitting us to process your data for this purpose.</li>
                    <li>Necessary for our legitimate interests (such as to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)</li>
                </ul>

                <p>To deliver relevant website and mobile app content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</p>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>Marketing and Communications</li>
                    <li>Technical</li>
                    <li>Your consent given to us permitting us to process your data for this purpose.</li>
                    <li>Necessary for our legitimate interests (such as to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)</li>
                </ul>

                <p>To use data analytics to improve our website, mobile apps, products/services, marketing, customer relationships and experiences</p>
                <ul>
                    <li>Technical</li>
                    <li>Usage</li>
                    <li>Necessary for our legitimate interests (such as to define types of customers for our products and services, to keep our website and mobile apps updated and relevant, to develop our business and to inform our marketing strategy)</li>
                </ul>

                <p>To make suggestions and recommendations to you about services that may be of interest to you</p>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Technical</li>
                    <li>Usage</li>
                    <li>Profile</li>
                    <li>Marketing and Communications</li>
                    <li>Necessary for our legitimate interests (such as to develop our products/services and grow our business)</li>
                </ul>

                <p>To verify the information that you provide to us.</p>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Transaction</li>
                    <li>Financial</li>
                    <li>Necessary for our legitimate interests (such as to investigate and prevent fraud on our platform)</li>
                    <li>Necessary to comply with a legal obligation</li>
                </ul>

                <p><strong>Marketing:</strong> We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.</p>
                <p><strong>Promotional offers from us:</strong> We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing). You will receive marketing communications from us if you have requested information from us, used our Services and consented to receiving marketing information from us by opting in to receive marketing information either through our agreement with you, your acceptance of our terms and conditions for use of our Services, or otherwise by communicating with you.</p>
                <p><strong>Third-party marketing:</strong> We will get your express consent before we share your personal data with any third party for marketing purposes either through agreement with you, your acceptance of our terms and conditions for use of our Services or otherwise by communicating with you.</p>
                <p><strong>Opting out:</strong> You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you or by contacting us at any time.</p>
                <p><strong>Cookies:</strong> You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of our website may become inaccessible or not function properly.</p>
                <p><strong>Information collected from job applicants:</strong> If you apply for a job on our website, we will collect the personal data you provide to us for the purpose of determining your qualifications for the position in which you have applied and to contact you to set up an interview.</p>
                <p><strong>Change of purpose:</strong> We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your personal data for an unrelated purpose, we will notify you. Please note that we may process your personal data without your knowledge or consent, where permitted under law.</p>
                <p><strong>User Generated Content:</strong> The Services may offer publicly accessible blogs or community forums or other ways to permit you to submit ideas, photographs, user profiles, writings, music, video, audio recordings, computer graphics, pictures, data, questions, comments, suggestions or other content, including your personal data (collectively, <b>“User Content”</b>). We, or other third parties may reproduce, publish, distribute or otherwise use User Content online or offline in any media or format. You should be aware that any User Content you provide may be published, collected, and used by others who access it. Please note that we do not control who will have access to the information that you choose to make public, and cannot ensure that parties who have access to such publicly available information will respect your privacy or keep your personal data secure. We would encourage you to consider this when deciding which information you share in respect of User Content.  This Privacy Policy does not apply to any information that you disclose publicly, share with others or otherwise upload, whether through the Services or otherwise. We are not responsible for the accuracy, use or misuse of any User Content that you disclose or receive through the Services.</p>

                <h2>‍DISCLOSURES OF YOUR PERSONAL DATA</h2>
                <p>We may share your personal data with the parties set out below for the purposes set out in the table above.</p>
                <ul>
                    <li>internal third parties such as companies affiliated with us;</li>
                    <li>external third parties central to the provisions of the Services to you such as our clients or partners that use the Beba Mzigo platform;</li>
                    <li>external third parties who we have partnered with to offer Services (or auxiliary services) to you;</li>
                    <li>external third parties such as service providers and governmental bodies (including law enforcement agencies) should we be required to do so;</li>
                    <li>third parties with whom you have given us your consent to share your personal data; and</li>
                    <li>third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this Privacy Policy;</li>
                </ul>

                <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
                <p>We will also disclose information, including Personal Data, to third parties:</p>
                <ul>
                    <li>when we are required to do so by a court order or by any regulatory authority;</li>
                    <li>where we are required to do so in order to comply with our legal and regulatory obligations in terms of any legislation or regulation applicable to us and our business (including fraud prevention and anti-money laundering);</li>
                    <li>in order to exercise, protect and defend our rights and property (including our intellectual property); or</li>
                    <li>where such disclosure is in the public interest.</li>
                </ul>

                <h2>TRANSFER OF DATA</h2>
                <p>We may transfer your Personal Data outside the jurisdiction where we collected it either for ease of delivery of Services, storage or other necessary form of processing. When such transfer is necessary and for your benefit, we ensure an appropriate and similar degree of protection is afforded to it by requiring implementation of relevant safeguards by the recipient of the personal data as required by applicable laws. By accepting this Privacy Policy, you are providing us with your express consent to transfer your personal data as laid out above.</p>

                <h2>RETENTION OF DATA</h2>
                <p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes for which it was collected, and for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. Your personal data may be retained for a longer period where you have consented to the retention, there has been a complaint, there is a prospect of litigation with respect to your use of our Services or for historical and statistical purposes.</p>

                <h2>DATA SECURITY</h2>
                <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
                <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

                <h2>YOUR LEGAL RIGHTS</h2>
                <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data. You have the right to:</p>
                <ul>
                    <li>be informed of the use to which your personal data is being put;</li>
                    <li>access your personal data;</li>
                    <li>object to the processing of your personal data;</li>
                    <li>request restriction of the processing of your personal data;</li>
                    <li>request for correction of false or misleading personal data;</li>
                    <li>request the transfer of your personal data;</li>
                    <li>request erasure of false or misleading personal data - we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request; and</li>
                    <li>withdraw consent at any time where we are relying on consent to process your personal data.</li>
                </ul>

                <p>If you wish to exercise any of the rights set out above or make a complaint with respect to how we process your personal data, please contact us. We may request specific information from you to enable us to confirm your identity, grant you access to your personal data or exercise any of your other rights in relation to such data. This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We will endeavor to respond to your complaint or request within fourteen days of its receipt. You will not have to pay a fee to access your personal data, or to exercise any of your rights in relation to the data, or to make a complaint. However, we may apply a charge if your request is clearly unfounded, repetitive or excessive. We may also refuse to comply with your request were permitted or required to do so under the law.</p>

                <p>Please remember that if you delete or limit the use of your personal data, you may not be able to access the Services.</p>

            </div>
            <Footer />
        </>
    )
}

export default Privacy;