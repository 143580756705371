import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from 'src/components/Footer';
import Navbar from 'src/components/Navbar';
import { userContext } from 'src/context/UserContext';
import { url } from 'src/helpers';


function Signup() {
    const { setUser, setLoad } = useContext(userContext);
    const [fname,setFname] = useState('')
    const [lname,setLname] = useState('')
    const [mobile,setMobile] = useState('')
    const [role,setRole] = useState("1")
    const [business,setBusiness] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');

    const history = useHistory()


    function submitLogin(e) {
        e.preventDefault();

        async function send() {
            let data = {
                "first_name" : fname,
                "last_name" : lname,
                "email": email,
                "password": password,
                "phone_number" : mobile,
                "business_name" : business,
                "user_type" : role

            }
            const response = await fetch(url + 'register', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            setLoad(false)

            if (response.ok == true) {
                const data = await response.json();
                if (data.status == 200) {
                    toast.success(data.message)
                    history.push("/login")
                } else {
                    toast.error(data.message)
                }
            } else {
                toast.error("Oops something went wrong!")
            }
        }

        if (cpassword == password) {
            setLoad(true)
            send().catch(err => {
                setLoad(false)
                toast.error(err.message)
            })
        } else {
            toast.error("Password & confirm password are not match")
        }

    }

    return (

        <>
            <Navbar type="full" />
            <div className='login-page-div custom-main-section'>
                <div className='inner-box d-flex flex-column align-items-center'>
                    <form onSubmit={(e) => submitLogin(e)}>
                        <div className='row w-100'>
                            <input required type="text" className='form-control col-md-6 my-2' placeholder='First Name' value={fname} onChange={e=>setFname(e.target.value)} />
                            <input required type="text" className='form-control col-md-6 my-2' placeholder='Last Name' value={lname} onChange={e=>setLname(e.target.value)} />
                            <input required type="email" className='form-control col-md-6 my-2' placeholder='Email Address' value={email} onChange={e=>setEmail(e.target.value)} />
                            <input required type="text" className='form-control col-md-6 my-2' placeholder='Mobile Number' value={mobile} onChange={e=>setMobile(e.target.value)} />
                            <select className='form-control col-md-6 my-2' value={role} onChange={e=>setRole(e.target.value)}>
                                <option value="1">Shipper</option>
                                <option value="2">Transporter</option>
                            </select>
                            <input required type="text" className='form-control col-md-6 my-2' placeholder='Business Name' value={business} onChange={e=>setBusiness(e.target.value)} />
                            <input required type="password" className='form-control col-md-6 my-2' placeholder='Password' value={password} onChange={e=>setPassword(e.target.value)} />
                            <input required type="password" className='form-control col-md-6 my-2' placeholder='Confirm Password' value={cpassword} onChange={e=>setCPassword(e.target.value)} />
                        </div>
                        <button type='submit' className='btn btn-success text-white mt-4'>SIGN UP TO BEBA MZIGO</button>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Signup;