import React, { useContext } from 'react'
import { userContext } from 'src/context/UserContext'

function Navbar({ type }) {
    const { user } = useContext(userContext)
    return (
        <div className={type == 'half' ? 'header-main-Y' : 'header-main-Y full-navbar'}>
            <nav className={type == 'half' ? 'navbar navbar-expand-lg navbar-light bg-light fixed-top header' : 'bg-white border-bottom header navbar navbar-expand-lg navbar-light shadow-sm'}>
                <div className="container-lg">
                    <div className="d-flex justify-content-between w-100 px-3">
                        <a className="navbar-brand" href="#">
                            <img className='img-fluid' src="./assets/images/logo.png" alt="" />
                        </a>
                        <button className="align-self-center navbar-toggler hidden-sm-up" type="button"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation" data-bs-toggle="collapse">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>
                <div className={type == 'half' ? "col-12 col-lg-9 right_toggle_menu pr-lg-0" : "col-12 col-lg-9 right_toggle_menu pr-lg-0"}>
                    <div className="p-detail">
                        <a href='tel:+254708135651' className="call">Call: +254708135651</a>
                        <a href='mailto:info@bebamzigo.com' className="email">Email: info@bebamzigo.com</a>
                        {user?.token ? <div className="login"><a href="/dashboard">My Account </a></div> : <div className="login"><a href="/login">LOG IN </a></div>}

                    </div>
                    <div className="collapse navbar-collapse pr-lg-3" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto" routerLinkActive="active">
                            <li className="nav-item dropdown" ngbDropdown>
                                <a className="nav-link" href="/">Home</a>
                            </li>
                            <li className="nav-item dropdown" ngbDropdown>
                                <a className="nav-link" id="id01" href="/#why-us">About Us</a>
                            </li>
                            <li className="nav-item dropdown" ngbDropdown>
                                <a className="nav-link" id="id01" href="/login">Ship Now</a>
                            </li>
                            <li className="nav-item dropdown" ngbDropdown>
                                <a className="nav-link" id="id01" href="/#contact-us">Contact Us</a>
                            </li>
                            <li className="nav-item drop down" id="loginBtMenu" ngbDropdown>
                                {user?.token ? <a className="nav-link" href="/dashboard">My Account</a> :
                                    <a className="nav-link" href="/login">Log In</a>
                                }
                            </li>
                            {/* <li className="nav-item dropdown" ngbDropdown>
                                <a className="nav-link" id="trackorder" href="https://bebamzigo.com/track-shipment">Track Your
                                    Order</a>
                            </li> */}

                            {/* <li className="nav-item dropdown" ngbDropdown>
                                    <a className="nav-link dropdown-toggle" id="id01" href="#" ngbDropdownToggle>Files</a>
                                    <div className="dropdown-menu" aria-labelledby="id01" ngbDropdownMenu>
                                        <a className="dropdown-item" href="#">HTML</a>
                                        <a className="dropdown-item" href="#">TS</a>
                                        <a className="dropdown-item" href="#">JS</a>
                                    </div>
                                </li> */}
                        </ul>
                    </div>
                </div>
            </nav >
        </div >
    )
}

export default Navbar