import React from "react";
import Footer from "src/components/Footer";
import Navbar from "src/components/Navbar";

function TransporterTerms() {
    return (
        <>
            <Navbar type="full" />

            <div className="termsPage">
                <h3 className="mb-4">Last updated: August 17, 2022</h3>

                <p>The following Terms and Conditions (“The Terms”) govern your access and/or use of any of the freight-related applications, websites, content, products, software and services (the “Services”) made available by the Beba mzigo and its representatives, affiliates, officers and directors (collectively “Beba mzigo”).</p>
                <p>PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION. BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND YOU UNDERSTAND ALL OF THE TERMS AND HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES.</p>
                <p>In these Terms, the words "including" and "include" mean "including, but not limited to."</p>
                <p>Use of the Services is conditioned on your agreement to all of the Terms contained herein, the Freight Transporter Agreement, and the policies and terms linked to or otherwise referenced herein (collectively, the “Agreement”). By accessing, using or receiving any Services made available by Beba mzigo, you expressly acknowledge and confirm your agreement to be bound by the Agreement.</p>
                <p>If you do not agree to these Terms, you may not access or use the Services. Beba mzigo may cease offering or deny access to the Services or any portion thereof, to you at any time for any reason. </p>
                <p>Beba mzigo reserves the right to amend the Terms or its policies relating to the Services at any time, without notice to you. Any such amendment will be effective upon posting of the updated Terms on the Services, and you are solely responsible for regularly reviewing the same. Your continued access or use of the Services after any such changes shall constitute your agreement to be bound by such Terms as amended.</p>
                <p>Please refer to our Privacy Policy located at <a href="/DriverPrivacy">https://www.bebamzigo.com/driver-privacy</a> for information about how we collect, use and disclose personal information in connection with the Services.</p>

                <h2>The Services</h2>
                <p>The Services provided by Beba mzigo enable a shipper registered on the Services (“Shipper”) to connect with a directory of transporters for the transportation of cargo (“Shipment”) subject to terms agreed to between the Shipper and a transporter registered on the Services (“Transporter”). </p>
                <p>YOUR ACCEPTANCE OF THESE TERMS DOES NOT MAKE YOU AN AGENT, LEGAL REPRESENTATIVE, JOINT VENTURE, OR PARTNER OF Beba mzigo FOR ANY PURPOSE. YOU UNDERSTAND AND AGREE THAT YOU WILL ACT AS AN INDEPENDENT CONTRACTOR AND ARE IN NO WAY AUTHORIZED TO MAKE ANY CONTRACT, WARRANTY OR REPRESENTATION ON BEHALF OF BEBA MZIGO, OR TO CREATE ANY OBLIGATION EXPRESS OR IMPLIED ON BEHALF OF BEBA MZIGO. IT IS EXPRESSLY AGREED AND UNDERSTOOD THAT YOU SHALL NOT BE CONSIDERED AS HAVING ANY EMPLOYMENT STATUS WITH BEBA MZIGO, OR AS BEING ENTITLED TO ANY PLANS, DISTRIBUTIONS, OR BENEFITS EXTENDED BY BEBA MZIGO TO ITS EMPLOYEES.</p>

                <h2>Description of Services</h2>
                <h4>Account</h4>
                <p>In order to use certain features of the Services, you must register for an account with Beba Mzigo (“Account”) and provide certain information as prompted by the applicable registration form. You represent and warrant that: (a) all required registration information you submit is truthful and accurate; and (b) you will maintain the accuracy of such information. You may delete your Account at any time by following the instructions on the Services. You are responsible for maintaining the confidentiality of your Account login credentials and agree to immediately notify Beba Mzigo of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. You may not authorize others to use your user status, and you may not assign or otherwise transfer your user account to any other person or entity. Beba Mzigo is not liable for any loss or damage arising from your failure to comply with the above requirements.</p>

                <h4>Shipment terms</h4>
                <p>Shipment terms will be negotiated and agreed between you and the Shipper, independently of the Services. Beba Mzigo is not responsible, and will bear no liability, for any Shipment terms entered into between you and any Shipper. By using the Services you hereby acknowledge and agree to the same. </p>
                <p>Beba Mzigo does not guarantee that your attempt to procure transportation of a Shipment will be successful, or that a Shipper will select you as its preferred Transporter. Further, Beba Mzigo will not bear responsibility for the actions and/or omissions of yourself or drivers in undertaking delivery.‍</p>

                <h4>Shipping documents‍</h4>
                <p>You shall not insert “Beba Mzigo” on any receipt, bill of lading, manifest, or other shipping document. You shall not name Beba Mzigo as either a shipper or consignee on any bill of lading. In the event you or the Shipper do so, such insertion shall be deemed to be due to your oversight, and shall not operate to alter your status as the responsible service provider. The provisions set forth in any shipping or other related document shall not supersede, alter, or modify these Terms or add any liability or responsibility to Beba Mzigo.‍</p>

                <h4>Insurance‍</h4>
                <p>You shall procure and maintain, at your sole cost and expense, with reputable and financially responsible insurance underwriters, acceptable to Beba Mzigo, the following insurance covers:</p>
                <ul style={{ listStyle: 'lower-alpha' }}>
                    <li>Workmen’s compensation in accordance with applicable laws, rules and regulations</li>
                    <li>At the least, third party general cartage insurance (for both trailer & prime mover) in accordance with applicable law;</li>
                    <li>Goods in Transit/ Carriers Liability Policy at such a cap as shall be determined by Beba Mzigo from time to time; and</li>
                    <li>Any other insurance that may be required by Beba Mzigo from time to time within applicable laws.</li>

                </ul>

                <p>If applicable law requires you to maintain other insurances not specified above, or minimum amounts in excess of the limits determined by Beba Mzigo, you shall provide such higher limits. You shall provide evidence of compliance in the manner communicated to you by Beba Mzigo which may include copies of certificates of insurance as well policy documents and endorsements. You hereby agree not to provide any transport services through the Services for any period of time during which you are not in compliance with these obligations related to insurance or any other condition or Term set out in the Agreement and that you will maintain the insurance covers referred to in this clause at all times when offering transport services through the Services. </p>
                <p>Notwithstanding the Transporter’s obligations under this clause, Beba Mzigo may at its sole discretion connect the Transporter to third party insurance underwriters for the provision of Goods in Transit/ Carriers Liability Policy at such a cap as shall be determined by Beba Mzigo from time to time to cover any Cargo transported by the Transporter under the Services. The Transporter agrees to cover the premium costs for such cover as may be advised by Beba Mzigo from time to time. In the event the Transporter fails to pay such premium as may be advised by Beba Mzigo, Beba Mzigo may set off such premium cost from any amounts due to the Transporter or recover such cost from the Transporter on demand.</p>
                <p>By requiring the insurance covers above, Beba Mzigo does not represent that coverage and limits will be adequate to protect the Transporter. The purchase of appropriate insurance coverage by the Transporter or the furnishing of a certificate/schedule of insurance shall not release the Transporter from its contractual obligations or liabilities to Beba Mzigo and/or the Shipper. </p>
                <p>Nothing in this Agreement shall be construed as placing any obligation on Beba Mzigo to procure any insurance cover or facilitate the procuring of any insurance cover required to be maintained by the Transporter under law or contract. </p>

                <h4>Vehicle Tracking</h4>
                <p>Beba Mzigo may, at its option, install a tracking device onto vehicles registered on its platform in relation to the Services. These devices may:</p>
                <ul>
                    <li>Be active at all times;</li>
                    <li>Be capable of identifying a vehicle’s location; and</li>
                    <li>Log routes travelled by the vehicle.</li>
                </ul>
                <p>The tracking information generated from the tracking device will be accessible to Beba Mzigo at all times. The information may, in addition, be made available to the Transporter.  If a Beba Mzigo engages a Transporter for Services, the Shipper may also be granted access to the tracking information and be able to monitor the vehicle’s location real-time while the vehicle is carrying the Shipper’s cargo pursuant to the contract entered between the Shipper and Transporter. Beba Mzigo may charge the Transporter reasonable fees for installation and use of the tracking devices at its sole discretion with no recourse to the Transporter. </p>
                <p>By accepting the Terms, you expressly consent to the collection and processing of location data and all other data collected by the tracking device(s) affixed to your vehicle(s) by Beba Mzigo and the Shipper as the case may be. You agree that such data collected by Beba Mzigo through the tracking devices is necessary for, among others, the purpose of improving the Services.</p>
                <p>The Transporter acknowledges that any tracking device installed by Beba Mzigo on a vehicle shall remain the sole property of Beba Mzigo and must be returned to Beba Mzigo forthwith on Beba Mzigo’s demand or in the event that the said vehicle is withdrawn from the Services. The Transporter agrees not to tamper with, or allow the tampering of, the tracking device in any way and to report any loss, damage, malfunction or any such thing that would make the tracking device not function properly. Any wilful or negligent destruction, loss and / or tampering of the tracking device will be actionable against the Transporter.</p>

                <h4>License Grant & Restrictions</h4>
                <p>Subject to your compliance with these Terms, Beba Mzigo hereby grants you a non‐exclusive, non‐transferable, revocable right to (a) access and use its applications and related services (the “Applications”) solely in connection with your use of the Services; and (b) access and use any content, information and related materials that may be made available through the Services solely for your personal, non-commercial use. All rights not expressly granted to you are reserved by Beba Mzigo and its licensors. </p>
                <p>You hereby agree NOT to:</p>
                <ul style={{ listStyle: 'lower-alpha' }}>
                    <li>License, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Services;</li>
                    <li>Remove any copyright, trademark or other proprietary notices from any portion of the Services;</li>
                    <li>Reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services except as expressly permitted by Beba Mzigo.</li>
                    <li>Attempt to gain unauthorized access to or impair any aspect of the Services or its related systems or networks;</li>
                    <li>Send or store material containing viruses, worms, trojan horses or other harmful computer code, files, scripts, agents or programs;</li>
                    <li>Interfere with or disrupt the integrity or performance of the Services or the information or data contained therein; or</li>
                    <li>Create internet ‘links’ to, or ‘frame’ or ‘mirror’ the Services, or any portion of the Services;</li>
                    <li>Decompile, reverse engineer or disassemble the Services except as may be permitted by applicable law;</li>
                    <li>Build a product or service using similar ideas, features, functions or graphics of the Services;</li>
                    <li>Copy any ideas, features, functions or graphics of the Services; or</li>
                    <li>Cause or launch any program or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Services, or which may make multiple server requests per second, or unduly burden or hinder the operation, performance and/or functionality of the Services.</li>
                </ul>

                <h4>‍Limited Support</h4>
                <p>You may contact Beba Mzigo for any support-related issues arising from the use of the Services by following the instructions on the Services. If these are unavailable, please contact Beba Mzigo through <a href="mailto:support@bebamzigo.com">support@bebamzigo.com.</a></p>

                <h4>Representations and Warranties</h4>
                <p>By using the Services, you expressly represent and warrant that you are legally permitted to enter into this agreement. The Services are not available to children (persons under the age of 18). By using the Services, you represent and warrant that you are at least 18 years old. </p>
                <p>By using the Services, you represent and warrant that you have the right, authority and capacity to enter into this agreement and to abide by the Terms. Your access and use of the Services is for your sole, personal use. You may not authorize others to use your user status, and you may not assign or otherwise transfer your user account to any other person or entity. When using the Services you agree to comply with all applicable laws of the Republic of Kenya. You agree to maintain accurate, complete and up-to-date information in your account. You may only access the Services using authorized means. It is your responsibility to check to ensure you download the correct software for your device. Beba Mzigo is not liable if you do not have a compatible handset or if you have downloaded the wrong version of software for your handset. Beba Mzigo reserves the right to terminate this agreement should you be accessing the Services with an incompatible or unauthorized device or Software.</p>

                <p>By using the Services, you agree that:</p>
                <ul style={{ listStyle: 'lower-alpha' }}>
                    <li>You will only use the Services for lawful purposes; you will not use the Services for sending or storing any unlawful material or for fraudulent purposes;</li>
                    <li>You shall perform all transportation services pursuant to these Terms in accordance with all applicable laws;</li>
                    <li>You hold all valid licenses necessary to perform transportation services under these Terms;</li>
                    <li>You will not send spam or otherwise duplicative or unsolicited messages in violation of applicable laws;</li>
                    <li>You will not use the Services to cause nuisance, annoyance or inconvenience;</li>
                    <li>You will not send or store infringing, obscene, threatening, libelous or otherwise unlawful or tortious material including material that violates third party rights;</li>
                    <li>You will not impair the proper operation of the network;</li>
                    <li>You will not try to harm the Shippers or Services in any way whatsoever;</li>
                    <li>You will not copy, or distribute the Services or other content received as a result of access or use of the Services without written permission from Beba Mzigo;</li>
                    <li>You will only use the Software and Services for your own personal use and will not resell any portion of the Services to a third party;</li>
                    <li>You agree that Beba Mzigo will not be liable for loss, damage, theft, destruction, or delayed delivery of any cargo;</li>
                    <li>You acknowledge that Beba Mzigo does not have ownership of the cargo and you will not take possession, custody or control of any cargo. Beba Mzigo does not assume any liability, possessory rights or obligations, and assumes no financial responsibility whatsoever, for cargo, including loss, theft, damage or delayed delivery thereof; and</li>
                    <li>You acknowledge that Beba Mzigo will not be responsible for securement of cargo for transportation.</li>
                </ul>

                <h4>‍Payment Terms</h4>
                <p>Upon completion of delivery for each Shipment, you will upload all the required supporting documentation and issue an invoice to the Shipper setting out your charges for that delivery (“Transporter Fee”). </p>
                <p>If you wish to make a request for an advance on the Services, then you will be required to include Beba mzigo’s payment details on the invoice issued to the Shipper. If the request is granted, Beba mzigo, or its agent for that purpose, shall remit to you an advance on the Transporter Fee net of the applicable discount. Upon the completion of the agreed credit period granted to the Shipper, Beba mzigo shall collect the full Transporter Fee from the Shipper. You shall not collect, or attempt to collect, including by way of an action, the Transporter Fee from any Shipper or any other third party. </p>
                <p>You agree that you are responsible for the collection and/or payment of all taxes, which you may be liable for arising from your use of the Services. Beba mzigo is not responsible for collecting, reporting, paying, or remitting to you any such taxes.</p>
                <p>Unless otherwise agreed in writing, the Transporter Fee stated in your invoice to the Shipper (“Invoice”) and agreed to by the Shipper is final. If any additional charges are applicable, you will issue an invoice for such additional amounts and obtain Shipper consent to the same. In the absence of such consent, neither Beba mzigo nor its agents shall make payment for the same.</p>
                <p>Beba mzigo may from time to time enter into arrangements with other vendors on a non-exclusive basis in order to extend auxiliary services to transporters on a credit basis. The auxiliary services shall only be made available upon request and subject to Beba mzigo’s discretion, after the confirmation of an order. You hereby acknowledge that any such credit advanced to you arising from the use of the Services shall be deducted from payment made to you. Any discounts, rebates, reductions or other benefits in prices received by Beba mzigo as a result of any such arrangement shall not be recoverable by you, unless Beba mzigo expressly offers the same to you.‍</p>

                <h4>‍Intellectual Party Ownership‍</h4>
                <p>Beba mzigo alone (and its licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights, in and to the Services and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by you or any other party relating to the Services. These Terms do not convey to you any rights of ownership in or related to the Services, or any intellectual property rights owned by Beba mzigo. The Beba mzigo name, Beba mzigo logo, and the product names associated with the Services are trademarks of Beba mzigo or third parties, and no right or license is granted to use them.‍</p>

                <h4>Third Party Interactions‍</h4>
                <p>The Services may be made available or accessed in connection with third party services and content (including advertising) that Beba mzigo does not control. During use of the Services, you may, without limitation, enter into correspondence with, purchase goods and/or services from, or participate in promotions of third party providers, advertisers or sponsors. You acknowledge that different terms of use, warranties, representations and privacy policies may apply to your use of such third party services and content and in no event shall Beba mzigo be responsible or liable for any representations, products or services of such third party providers. You acknowledge that your access and use of the Services in connection with third party services may be subject to terms set forth in the applicable third party’s terms and conditions. You acknowledge that the availability of the products, goods or services of third party providers on the Services is not an endorsement of the same by Beba mzigo. You hereby further acknowledge that where third party providers of goods and/or services require your agreement to additional or separate terms and conditions prior to your use of or access to such goods or services, Beba mzigo will have no responsibility nor liability arising from such agreements between you and the third party providers.</p>
                <p>Any third party interactions, and any terms, conditions, warranties or representations associated with such activity, are solely between you and the applicable third party. Beba mzigo and its licensors shall have no liability, obligation or responsibility for any such correspondence, purchase, transaction or promotion between you and any such third party. Beba mzigo does not endorse any sites on the Internet that are linked through the Services, and in no event shall Beba mzigo or its licensors be responsible for any content, products, services or other materials on or available from such sites or third party providers.</p>
                <p>Beba mzigo may rely on third party advertising and marketing supplied through the Services and other mechanisms to subsidize the Services. By agreeing to these Terms you agree to receive such advertising and marketing. Beba mzigo may compile and release information regarding you and your use of the Services on an anonymous basis as part of a customer profile or similar report or analysis. You agree that it is your responsibility to take reasonable precautions in all actions and interactions with any third party you interact with through the Services.‍</p>

                <h4>Indemnification‍</h4>
                <p>You agree that you shall defend, indemnify and hold Beba mzigo, its licensors and each such party's parent organizations, subsidiaries, affiliates, officers, directors, members, employees, attorneys and agents harmless from and against any and all claims, costs, damages, demands, losses, liabilities and expenses (including legal fees and costs) arising out of or in connection with: (a) your violation or breach of any of these Terms or any applicable law or regulation, whether or not referenced herein; (b) your violation of any rights of any user of the Services; (c) your use or misuse of the Services or services or goods obtained through your use of the Services or; (d) Beba mzigo’s use of your data and user content in accordance with these Terms.</p>
                <p>You agree to defend, indemnify and hold Shippers (and their officers, employees, and agents) harmless, including legal fees and costs from any and all damages, claims or losses arising out of your performance of these Terms, to the extent such damages, claims or losses are caused by you or your employees’ or agents’ negligence or intentional conduct.‍</p>

                <h4>Disclaimer of Warranties‍</h4>
                <p>THE SERVICES ARE PROVIDED TO YOU STRICTLY ON AN "AS IS" BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON‐INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED BY BEBA MZIGO. BEBA MZIGO MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICES. BEBA MZIGO MAKES NO REPRESENTATIONS OR WARRANTIES THAT (A) THE USE OF THE SERVICES WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR‐FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA, (B) THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED DATA WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (E) ERRORS OR DEFECTS IN THE SERVICES WILL BE CORRECTED, OR (F) THE SERVICES OR THE SERVER(S) THAT MAKE THE SERVICES AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. BEBA MZIGO MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE AS TO THE RELIABILITY, SAFETY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF ANY SERVICES, PRODUCTS OR GOODS SUPPLIED BY THIRD PARTIES THROUGH THE USE OF THE SERVICES. BEBA MZIGODOES NOT ASSESS THE SUITABILITY, LEGALITY, REGULATORY COMPLIANCE, QUALITY OR ABILITY OF A SHIPPER OR SHIPPED ITEMS SCHEDULED THROUGH THE USE OF THE SERVICES. YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY THIRD PARTY SERVICES OR PRODUCTS REMAINS SOLELY WITH YOU.‍</p>

                <h4>Internet Delays</h4>
                <p>The Services may be subject to limitations, delays, and other problems inherent in the use of the internet and electronic communication. Beba Mzigo is not responsible for any delays, delivery failures, or any other liability, costs or damage resulting from such problems.‍</p>

                <h4>Notice‍</h4>
                <p>Beba Mzigo may give notice by means of a general notice on the Services, electronic mail to your email address in your account, telephone or text message to any phone number provided in connection with your account, or by a notification message displayed on your account page. Such notice shall be deemed to have been given upon publication on the Services or upon the expiration of 12 hours after sending (if sent by email or telephone). It shall be your responsibility to keep your contact information (including email address) current and you will be deemed to have received any notice issued based on the contact information in your account, whether or not you actually receive it. You may give notice to Beba Mzigo, with such notice deemed given when received by Beba Mzigo, at any time through the email address <a href="mailto:support@bebamzigo.com">support@bebamzigo.com</a></p>
                <p>Beba Mzigo reserves the right to close or suspend your account without prior warning should you contravene any of these Terms or any future amendments and additions thereto. </p>

                <h4>Assignment</h4>
                <p>You may not assign these Terms without the prior written approval of Beba Mzigo. Beba Mzigo may assign these Terms without your consent by Beba Mzigo to (a) a parent, subsidiary or affiliate; (b) an acquirer of Beba Mzigo’s equity, business or assets; or (c) a successor by merger. Any purported assignment in violation of this section shall be void. </p>

                <h4>Data Privacy</h4>
                <p>Beba Mzigo’s collection and use of personal information in connection with the Services is described in our Data Privacy Policy located at https://www. bebamzigo.com/privacy  the terms of which are incorporated into these Terms. Any data, including personal data, shared with Beba Mzigo will be used in accordance with this Data Privacy Policy.
                    By accessing or using the Services, you hereby freely, unequivocally and expressly authorize Beba Mzigo to receive, record, process, store and transfer your data, including personal data, in accordance with the law.
                </p>

                <p>You hereby freely, unequivocally and expressly consent to:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The transfer of your data, including personal data, to third parties to be used for credit checks and other verification purposes;</li>
                    <li>The display of your data, including personal data, on the Services for the purpose of discovery by Shippers;</li>
                    <li>Sharing of your data with third parties:
                        <ul style={{ listStyle: 'lower-alpha' }}>
                            <li>Who provide financing and auxiliary services to Transporters in order to make these services available to you;</li>
                            <li>If there is a claim, complaint, dispute, conflict or accident arising from and/ or in connection with your use of the Services;</li>
                            <li>If it is necessary to enforce the terms of the Terms or any other agreement we may enter into with you;</li>
                            <li>Where it is required under any applicable law or to comply with a request from law enforcement agencies or regulatory agencies;</li>
                            <li>Where in Beba Mzigo’s sole opinion, it is necessary to investigate or stop any activity relating to the Services that may amount to fraudulent or unethical behaviour, or that may be legally actionable;</li>
                            <li>In the event Beba Mzigo is required to share such information for insurance purposes or to extend any services that we offer you; or</li>
                            <li>Where it is necessary for Beba Mzigo to protect its rights and / or legitimate interests whether against the Transporter or a third party.</li>
                        </ul>
                    </li>
                </ul>
                <p>By entering into this Agreement, you consent to Beba Mzigo processing and sharing your personal information and data as set out in this clause, and you authorize Beba Mzigo to process, store, transfer, change and delete all personal data in connection with this relationship.</p>

                <h4>Confidentiality</h4>
                <p>By accessing or using the Services, you hereby agree and undertake not to disclose to any person any confidential information concerning the business, affairs, customers, data, clients or suppliers of Beba Mzigo and the Shippers save as is expressly required by law. You also unequivocally agree not to use any information obtained through the Services for any purpose other than that which you are expressly permitted by Beba Mzigo to do. Any violation of this provision shall warrant a charge, for each such violation, that shall be determined by Beba Mzigo in its sole discretion, bearing in mind: (1) the loss and expense occasioned by such loss; and (2) any deterrence measures Beba Mzigo may wish to pursue.</p>

                <h4>Publication of Information</h4>
                <p>If you provide Beba Mzigo any feedback, suggestions, or other information or ideas regarding the Service (“Feedback”), you hereby assign to Beba Mzigo all rights in the Feedback and agree that Beba Mzigo has the right to use such Feedback and related information in any manner it deems appropriate. Beba Mzigo will treat any Feedback you provide as non-confidential and non-proprietary. You agree that you will not submit to Beba Mzigo any information or ideas that you consider to be confidential or proprietary.</p>

                <h4>Non-Solicitation</h4>
                <p>You shall not, except with the prior written consent of Beba Mzigo, attempt to solicit or entice away; or solicit or entice away, from the service of Beba Mzigo, the services of any Shipper, for any duration of time however brief. This provision shall continue in force for one (1) year subsequent to termination of usage of the Services. In the event of a violation, you shall be jointly and severally liable with the Shipper to Beba Mzigo and shall be charged, for each such violation, an amount equal to twenty-five percent (25%) of all revenues paid to you by the Shipper.</p>

                <h4>Miscellaneous Provisions</h4>
                <p><strong>Responsibility.</strong> If you authorize, assist, encourage or facilitate another person or entity to take any action related to the subject matter of these Terms with Beba Mzigo, you shall be deemed to have taken the action yourself.</p>
                <p><strong>Severability.</strong> If any portion of these Terms is held by a court of competent jurisdiction to be invalid or unenforceable, the remaining provisions of these Terms will remain in full force and effect, and any invalid or unenforceable portions shall be construed in a manner that most closely reflects the effect and intent of the original language. If such construction is not possible, the provision will be severed from these Terms, and the rest of the Terms shall remain in full force and effect.</p>
                <p><strong>Waivers.</strong> Any failure by Beba Mzigo to enforce any right or provision of these Terms shall in no way be construed to be a present or future waiver of such provision nor in any way affect our right to enforce such provision thereafter. All waivers by us must be acknowledged and agreed in writing.</p>
                <p><strong>Successors and Assigns.</strong> These Terms will be binding upon, and inure to the benefit of Beba Mzigo and any contracting parties and their respective successors and assigns.</p>
                <p><strong>No Agency.</strong> No partnership, contract of employment, agency, joint venture or franchise relationship shall be created between you, Beba Mzigo or any Transporters as a result of these Terms or use of the Services.</p>
                <p><strong>No Third Party Beneficiary.</strong> You acknowledge and agree that, except as otherwise expressly provided in these Terms, there shall be no third party beneficiary to any agreement entered into with Beba Mzigo.</p>
                <p><strong>International Sale of Goods, Export and Import Control.</strong> Services, content, and products derived or obtained from the Services may be subject to Kenya import or export laws, and/or the export or import laws of other countries. You agree to comply strictly with all such laws and, in particular, to: (a) obtain any export, re‐export or import authorizations required by Kenya or your local laws; and (b) not provide services, content, or products from the Services to any prohibited countries and/or prohibited entities as identified in any Kenyan legislation.</p>
                <p><strong>Choice of Law.</strong> These Terms are governed by and construed in accordance with the laws of Kenya without giving effect to any conflict of law principles. The United Nations Convention on Contracts for the International Sale of Goods will not apply to these Terms and are hereby expressly excluded.</p>
                <p><strong>Headings.</strong> Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section.</p>

            </div>

            <Footer />
        </>
    )
}


export default TransporterTerms;