import React, { createContext, useEffect, useState } from 'react'

const userContext = createContext();

function fetchUserFromLocalStorage (){
    return localStorage.getItem("bebamzigo_user") ? JSON.parse(localStorage.getItem("bebamzigo_user")) : null
}

function UserContext(props) {
    const [user, setUser] = useState(fetchUserFromLocalStorage())
    const [load, setLoad] = useState(false)

    useEffect(()=>{
        localStorage.setItem("bebamzigo_user",JSON.stringify(user))
    },[user])

    
    return (
        <userContext.Provider value={{user, setUser, load, setLoad}}>
            {props.children}
        </userContext.Provider>
    )
}

export {userContext,UserContext}