import React from 'react'
import Footer from 'src/components/Footer';
import Navbar from 'src/components/Navbar';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


function Home() {

    const options = {
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            850: {
                items: 4
            },
            1200: {
                items: 5
            }
        }
    };

    return (
        <>
            <Navbar type="half" />

            {/* < slider code */}
            <div className="home-slider-main-Y">
                <div className="banner-slider">
                    <div className="container slider_container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="slide_heading">
                                    <h2>
                                        Empowering corporate <br />
                                        businesses and individuals.
                                    </h2>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap gap-3">
                                <a href='/login' className="join_us">JOIN BEBA MZIGO</a>
                                {/* <button className="download_app">DOWNLOAD APP</button> */}
                            </div>
                            <div className="col-md-4">

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div id="sect1"className="container">
        <h2>Now Load image on the displa\</h2>
        </div> */}
            </div>


            {/* our services code */}
            <div className="our-services-main-Y">
                <div className="slider">
                    <div className="our_services">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="our_services_head">
                                        <h2>Our Services</h2>
                                        <hr />
                                        <p>Come on board and easily achieve end-to-end logistics.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm-10 col-md-5 col-lg-4 col-xl-3">
                                    <div className="our_services_card">
                                        <h4>SHIPPER</h4>
                                        <div className="card">
                                            <p>Move anything with Ease
                                                & Transparently at a click
                                                of a button.</p>
                                            <a href='/login'>Move Goods</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 col-md-5 col-lg-4 col-xl-3 pt-4 pt-md-0">
                                    <div className="our_services_card transporter">
                                        <h4>TRANSPORTER</h4>
                                        <div className="card">
                                            <p>Maintaining a fleet of
                                                vehicles can be
                                                overwhelming but at
                                                Beba Mzigo we got you
                                                covered. </p>
                                            <a href='/login'>JOIN US TODAY</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* why us code */}
            <div className="why-us-main-Y">
                <div className="slider" id="why-us">
                    <div className="why_us">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="why_us_head">
                                        <h2>Why Beba Mzigo</h2>
                                        <hr />
                                        <p className="text">
                                            We provide a safe and trustworthy platform for end-to-end
                                            logistics for all businesses.Once transporters go through an
                                            intensive vetting process, they are allowed to bid on loads. When
                                            a shipper and transporter reach agreement on transportation costs,
                                            an electronic contract is generated. Then a shipper must deposit
                                            the payment in full. This payment is held in an escrow account.The
                                            transporter is only paid once goods are delivered and the receiver
                                            has verified receipt. This creates trust in the logistics chain
                                            and ensures your goods are delivered safely and in a timely
                                            manner.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="why_us_mid_section">
                                <div className="row flex-column-reverse flex-md-row">
                                    <div className="col-md-5">
                                        <div className="why_us_left_text">
                                            <p>Scale up your business
                                                instantly. You will gain access
                                                to thousands of lorries,
                                                pickups, vans, tuk-tuks and
                                                bodabodas at a click of a
                                                button.</p>
                                            <a href="/login">MOVE NOW</a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="why_us_right_img">
                                            <img src="./assets/images/logistics-process.jpeg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="why_us_mid_two_section pt-xl-0 pt-5">
                                <div className="why_us_head">
                                    <h2>Safety for your precious goods</h2>
                                    <hr />
                                </div>
                                <div className="row flex-column-reverse flex-md-row">
                                    <div className="col-md-5">
                                        <div className="why_us_left_text">
                                            <p>Your goods will be shipped
                                                safely & in a timely manner at a
                                                pocket-friendly price</p>
                                            <a href="/login">MOVE NOW</a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="why_us_right_img">
                                            <img src="./assets/images/unnamed.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* contact us code */}
            <div className="contact_us_main">

                <div className="contact_us" id="contact-us">
                    <div className="contact_us_wrapper">
                        <div className="container-lg">
                            <div className="row justify-content-center card_wrapper">
                                <div className="col-md-4 col-xl-3">
                                    <div className="Main_address cards">
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        <h4>Our Main Office</h4>
                                        <p>Muthiga, Kiambu-Kenya</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xl-3">
                                    <div className="Main_address cards">
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                        <h4>Phone Number</h4>
                                        <p className="mb-0">254740564903</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xl-3">
                                    <div className="Main_address cards">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                        <h4>Email</h4>
                                        <p>info@bebamzigo.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center contact_us_form">
                                <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-11">
                                    <div className="form">
                                        <h2>Contact Us</h2>
                                        <form>
                                            <div className="form-group">
                                                <input type="text" placeholder="Enter Full Name" className="form-control" />
                                                <div className="invalid-feedback">
                                                    <div>
                                                        Fullname is required
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <input type="text" placeholder="Enter a valid email address" className="form-control" />
                                                <div className="invalid-feedback">
                                                    <div>Email is required</div>
                                                    <div>Email is invalid</div>
                                                </div>
                                            </div>

                                            <div className="form-group text_area">
                                                <textarea rows="5" cols="56" className="form-control">
                                                </textarea>
                                            </div>
                                            <div className="form-group form_button">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* our partner code */}

            <div className="our_partner_main">
                <div className="our_partners">
                    <div className="our_partners_wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="our_partners_head">
                                        <h2>OUR PARTNERS</h2>
                                        <hr />
                                        <p className="text">
                                            From a Kenyan start up to an African-wide trade platform <br />
                                            empowering over 1000k+ customers to achieve more.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="our_partners_wrapper_img">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 text-center">
                                    <div className="our_partners_img">
                                        <a href="https://demisystems.co.ke" target='_blank'>
                                            <img src="./assets/images/demi-systems.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 text-center">
                                    <div className="our_partners_img">
                                        <a href="https://demishop.co.ke" target='_blank'>
                                            <img src="./assets/images/demi-shop.jpg" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 text-center">
                                    <div className="our_partners_img">
                                        <a href="https://luthuli.co.ke" target='_blank'>
                                            <img src="./assets/images/luthuli.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* our client say code */}
            <div className="our_client_main" style={{display: 'none'}}>
                <div className="slider">
                    <div className="our_client">
                        <div className="container-lg">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="our_client_head">
                                        <h2>OUR CLIENTS SAY</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="our_client_video">
                                        <h2>Video Testimonials</h2>
                                        <div className="main-video">
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <h3 className='mb-1'>AR Salon</h3>
                                                    <h4>Amil Pabalan</h4>
                                                    <p>Lorem ipsum dolor sit amet officiis delectus! Velit inventore voluptatibus voluptates minima. Suscipit eaque molestiae reprehenderit deserunt. Debitis, cupiditate!</p>
                                                </div>
                                                <div className='col-md-7'>
                                                    <iframe width="auto" height="auto" src="https://www.youtube.com/embed/OsTGp5n6w5E?si=acicNc3sj4Kep53h" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                                </div>
                                            </div>

                                            <div className='more-video-div mb-4'>
                                                <p>More Videos</p>
                                            </div>

                                            <OwlCarousel className='owl-theme' items={5} dots={false} loop margin={10} nav {...options}>
                                                <div class='item'>
                                                    <h4>1</h4>
                                                </div>
                                                <div class='item'>
                                                    <h4>2</h4>
                                                </div>
                                                <div class='item'>
                                                    <h4>3</h4>
                                                </div>
                                                <div class='item'>
                                                    <h4>4</h4>
                                                </div>
                                                <div class='item'>
                                                    <h4>5</h4>
                                                </div>
                                                <div class='item'>
                                                    <h4>6</h4>
                                                </div>
                                                <div class='item'>
                                                    <h4>7</h4>
                                                </div>
                                                <div class='item'>
                                                    <h4>8</h4>
                                                </div>
                                                <div class='item'>
                                                    <h4>9</h4>
                                                </div>
                                                <div class='item'>
                                                    <h4>10</h4>
                                                </div>
                                                <div class='item'>
                                                    <h4>11</h4>
                                                </div>
                                                <div class='item'>
                                                    <h4>12</h4>
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}

export default Home;