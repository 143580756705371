import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from 'src/components/Footer';
import Navbar from 'src/components/Navbar';
import { userContext } from 'src/context/UserContext';
import { url } from 'src/helpers';


function LoginPage() {
    const { setUser, setLoad } = useContext(userContext);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const history = useHistory()


    function submitLogin(e) {
        e.preventDefault();

        async function send() {
            let data = {
                "email": email,
                "password": password,
            }
            const response = await fetch(url + 'login', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            setLoad(false)

            if (response.ok == true) {
                const data = await response.json();
                if (data.status == 200) {
                    setUser({
                        token: data?.token,
                        ...data?.logged_data
                    })

                    history.push("/dashboard")
                } else {
                    toast.error(data.message)
                }
            } else {
                toast.error("Oops something went wrong!")
            }
        }

        if (email && password) {
            setLoad(true)
            send().catch(err => {
                setLoad(false)
                toast.error(err.message)
            })
        } else {
            toast.error("Email and password are required")
        }

    }

    return (

        <>
            <Navbar type="full" />
            <div className='login-page-div custom-main-section'>
                <div className='inner-box d-flex flex-column align-items-center'>
                    <div className='w-100'>
                        <form onSubmit={(e) => submitLogin(e)}>
                            <input type="email" className="form-control form-control-lg mt-2" required value={email} onChange={e => setEmail(e.target.value)} placeholder='Email Address' />
                            <input type="password" className="form-control form-control-lg mt-3" required placeholder='Password' value={password} onChange={e => setPassword(e.target.value)} />
                            <div className='w-100 d-flex justify-content-end'>
                                <a href="/ForgetPasswordEmail">Forget Password?</a>
                            </div>
                            <div className='w-100 d-flex justify-content-center align-items-center'>
                            <button href="#" className='btn btn-success text-white mt-4'>SIGN IN TO BEBA MZIGO</button>
                            </div>
                        </form>
                    </div>
                    <a href='/signup'>Don't have a account?. Create a new account</a>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default LoginPage;