import React from "react";
import Footer from "src/components/Footer";
import Navbar from "src/components/Navbar";

function TermsOfServices() {
    return (
        <>
            <Navbar type="full" />

            <div className="termsPage">
                <h3 className="mb-4">Last updated: August 17, 2022</h3>

                <p>The following Terms and Conditions (the “Terms”) govern your access and/or use of any of the fulfillment-related applications, websites, content, products, software and services (the “Services”) made available by the Beba Mzigo Group of Companies, and its representatives, affiliates, officers and directors (collectively “Beba Mzigo”).</p>
                <p>PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION. BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND YOU UNDERSTAND ALL OF THE TERMS AND HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES.</p>
                <p>Use of the Services is conditioned on your agreement to all of the Terms contained herein and  the policies and terms linked to or otherwise referenced herein (collectively, the “Agreement”). By accessing, using or receiving the Services made available by Beba Mzigo, you expressly acknowledge and confirm your agreement to be bound by the Agreement.</p>
                <p>If you do not agree to these Terms, you may not access or use the Services. Beba Mzigo may cease offering or deny access to the Services or any portion thereof, to you
                    at any time for any reason.
                </p>
                <p>Beba Mzigo will notify you should the Terms or Beba Mzigo policies be amended including through updating the amended Terms on its online platforms. Any such amendment will be effective upon posting of the updated Terms on the Services, and you are solely responsible for regularly reviewing the same. Your continued access or use of the Services after any such changes shall constitute your agreement to be bound by such Terms as amended.</p>
                <p>Please refer to our Privacy Policy located at <a href="#">https://www.bebamzigo.com/privacy</a> for information about how we collect, use, process and disclose personal information in connection with the Services.</p>
                <p>Unless otherwise agreed by beba Mzigo in a separate written agreement with you, the Services are made available solely for your use and may not be resold to third parties.</p>


                <h2>Account</h2>
                <p>In order to use the Services, you must register an account with Beba Mzigo (“Account”) and provide certain information as prompted by the applicable registration form. You represent and warrant that: (a) all required registration information you submit is truthful and accurate; and (b) you will maintain the accuracy of such information. You may delete your Account at any time by following the instructions on the Services. You are responsible for maintaining the confidentiality of your Account login credentials and agree to immediately notify Beba Mzigo of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. You may not authorize others to use your user status, and you may not assign or otherwise transfer your user account to any other person or entity. Beba Mzigo is not liable for any loss or damage arising from your failure to comply with the above requirements.</p>


                <h2>The Services</h2>
                <p>The Services provided by Beba Mzigo enable you to arrange and schedule for the collection, storage, packaging and preparation of goods for delivery to your consignees. You acknowledge that all delivery services offered through the Services are provided by independent third party transporters (the “Transporters” and individually a “Transporter”). YOU HEREBY ACKNOWLEDGE THAT YOUR ABILITY TO OBTAIN DELIVERY AND/OR LOGISTICS SERVICES THROUGH THE USE OF THE SERVICES DOES NOT ESTABLISH Beba Mzigo AS A PROVIDER OF DELIVERY, COURIER OR LOGISTICS SERVICES OR AS A LOGISTICS CARRIER.</p>

                <p className="mb-2">In consideration of you paying the fees communicated by Beba Mzigo from time to time and in the manner set out in these Terms, Beba Mzigo will provide the Services in accordance with this Agreement. </p>
                <ul>
                    <li>The Services will be provided on demand and you will be required to place an order through your Beba Mzigo Account by inputting accurate information requested by Beba Mzigo to enable Beba Mzigo offer the Services to you. This may include but is not limited to:</li>
                    <ul>
                        <li>Pickup address for the goods: The physical address for collection of the goods including any Beba Mzigo distribution hubs. Please note that you may also personally drop off goods at any Beba Mzigo warehouse or distribution hub as may be communicated by Beba Mzigo to you or request Beba Mzigo to collect the goods from your premises subject to such terms as Beba Mzigo may communicate to you.</li>
                        <li>Destination: The physical address for delivery of the goods to the consignee once available.</li>
                        <li>Contact details of the consignee once available including their telephone number or any other details requested by Beba Mzigo.</li>
                        <li>An accurate description of the goods that will be the subject of the Services.</li>
                    </ul>

                    <li>A Transporter will be assigned to pick up and deliver the goods to a Beba Mzigo designated warehouse or goods holding area. You will be able to manage and track your order through your Beba Mzigo Account. A Transporter may, for any reason, request a physical verification of the goods to ensure that they conform with the Terms before pick up. If the inspection reveals any unauthorised or damaged goods or goods not matching the description provided by you, the Transporter shall decline receipt and shall notify Beba Mzigo.</li>
                    <li>Beba Mzigo will offer storage of the goods under the Services at its designated warehouses pending your instructions to deliver the goods to a consignee. Such storage fee shall be charged on a monthly basis. Beba Mzigo, may in its sole discretion waive such storage fee for any specified period of time. At any time when the goods are in the possession of Beba Mzigo, you may make a delivery request to a specified consignee and once Beba Mzigo receives such a request, a Transporter will be dispatched on the next business day to collect the goods from the warehouse for delivery to the consignee at the specified destination. Your consignee will receive communication, through their contact details provided to us by you, informing them of the delivery and will have the option of postponing the delivery for a maximum period of 2 days. If a Transporter is unable to complete a delivery due to consignee availability or postponement the goods will be returned to the warehouse and the status of the order updated. In the event that an accident occurs while the goods are in Beba Mzigo’s possession or the Transporter’s possession, Beba Mzigo shall notify you as soon as possible.</li>
                    <li>Beba Mzigo reserves the right to charge a storage fee for goods that remain in Beba Mzigo’s custody beyond the minimum period notified to you through the Services.</li>
                    <li>Beba Mzigo will ensure the goods delivered to your consignees are sealed. Once the goods are accepted the order will be marked as complete on your Account and the fulfillment transaction will be considered closed. You acknowledge that we will not accept returns from the consignees as our contractual relationship for the Services is with you and not the consignee. In the event the consignee wishes to return the goods delivered, then after the fulfillment transaction is closed, you may approve any such return and may instruct Beba Mzigo to assist in the delivery of such return to you under a separate transaction.</li>
                    <li>In the event that you require any return of any warehoused goods through the Services, you may place a request through your Account and Beba Mzigo will communicate when the goods will be delivered to the specified location and upon such delivery, the order will be marked as complete on your Account. Beba Mzigo reserves the right to charge a fee for any returns you request.</li>
                </ul>

                <h2>Payment Terms</h2>
                <p>Fulfillment fees applicable for the Services shall be communicated to you through the Services. You will pay Beba Mzigo for the Services the fulfillment fees in advance and in full prior to the completion of a delivery in accordance with either of the following options:</p>
                <ul>
                    <li>On-pay - where you pay the amounts quoted by Beba Mzigo through the Services prior to placing any delivery requests and/or immediately upon the conclusion of a delivery; or</li>
                    <li>Pre-pay- where you top up your Beba Mzigo account with an amount from which the delivery fees will be deducted based on consumption.</li>
                </ul>

                <p>Payments shall be made via bank transfer, mobile money transfer or other agreed digital means. Cash payments shall not be accepted unless expressly advised by Beba Mzigo.</p>
                <p>The fees are exclusive of all taxes. If taxes are chargeable, you agree to pay them in addition to the fees.,<br />
                    You understand that Beba Mzigo, in its sole discretion, may also vary its fees using any criteria including without limitation, geographical location, time period or quantum of demand. You also understand that such variance may result in different amounts charged for the same or similar services obtained through the use of the Services. Any such variance in fees will be communicated to you through the Services.</p>
                <p>Beba Mzigo may, at its sole discretion, make promotional offers with different features and different rates to any of its customers or groups of our customers. These may result in different amounts charged for the same or similar services or goods obtained through the use of the Services. You agree that these promotional offers, unless made to you, shall have no bearing whatsoever on your contract, your use of the Services or the Charges applicable to you.</p>
                <p>Beba Mzigo reserves the right to, at its sole-discretion, allow you to post-pay for the Services subject to such terms and conditions agreed by you and Beba Mzigo in writing, provided that in all circumstances, fees for Services rendered must be settled within 24 hours of provision of the Services.</p>

                <h2>License Grant & Restrictions</h2>
                <p>Subject to your compliance with these Terms, Beba Mzigo hereby grants you a non‐exclusive, non‐transferable, revocable right to (a) access and use its Fulfillment applications and related services (the “Applications”) solely in connection with your use of the Services; and (b) access and use of any content, information and related materials that may be made available through the Services solely for your personal, non-commercial use. All rights not expressly granted to you are reserved by Beba Mzigo and its licensors. </p>

                <p>You hereby agree NOT to:</p>
                <ul style={{ listStyle: 'lower-alpha' }}>
                    <li>License, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Services</li>
                    <li>Remove any copyright, trademark or other proprietary notices from any portion of the Services and/or Applications</li>
                    <li>Reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services except as expressly permitted by Beba Mzigo</li>
                    <li>Attempt to gain unauthorized access to or impair any aspect of the Services or its related systems or networks</li>
                    <li>Send or store material containing viruses, worms, trojan horses or other harmful computer code, files, scripts, agents or programs</li>
                    <li>Interfere with or disrupt the integrity or performance of the Services or the information or data contained therein; or</li>
                    <li>Create internet ‘links’ to, or ‘frame’ or ‘mirror’ the Services, or any portion of the Services</li>
                    <li>Decompile, reverse engineer or disassemble the Services except as may be permitted under applicable law</li>
                    <li>Build a product or service using similar ideas, features, functions or graphics of the Services</li>
                    <li>Copy any ideas, features, functions or graphics of the Services; or</li>
                    <li>Cause or launch any program or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Services, or which may make multiple server requests per second, or unduly burden or hinder the operation, performance and/or functionality of the Services.</li>
                </ul>

                <h2>Support</h2>
                <p>Users may contact Beba Mzigo for any support-related issues arising from the use of the Services by following the instructions on the Services.</p>

                <h2>Representations and Warranties</h2>
                <p>By using the Services, you expressly represent and warrant that you are legally permitted to enter into the Agreement. The Services are not available to children (persons under the age of 18). By using the Services, you represent and warrant that you are at least 18 years old.</p>
                <p>By using the Services, you represent and warrant that you have the right, authority and capacity to enter into this agreement and to abide by the Terms. Your access and use of the Services is for your use and may not be resold to third parties. When using the Services you agree to comply with all applicable laws of the Republic of Kenya. You may only access the Services using authorized means. It is your responsibility to check to ensure you download the correct software for your device. Beba Mzigo is not liable if you do not have a compatible handset or if you have downloaded the wrong version of software for your device. Beba Mzigo reserves the right to terminate this Agreement should you be accessing the Services with an incompatible or unauthorized device or Software.
                </p>

                <p>You agree that by using or accessing the Services:</p>
                <ul>
                    <li>You will not alter, modify, translate, adapt, decompile, disassemble or reverse engineer the Applications or Services, nor any part of it</li>
                    <li>You will not send spam or otherwise duplicative or unsolicited messages in violation of applicable laws</li>
                    <li>You will not use the Services to cause nuisance, annoyance or inconvenience</li>
                    <li>You will not impair the proper operation of the Services</li>
                    <li>You will not copy, or distribute the Services or other content received as a result of access or use of the Services without written permission from Beba Mzigo</li>
                    <li>You will only use the Applications and Services for your own personal use and will not resell any portion of the Services to a third party</li>
                    <li>You will keep secure and confidential your account password or any identification we provide you which allows access to the Services</li>
                    <li>You agree to immediately notify Beba Mzigo of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. Beba Mzigo is not liable for any loss or damage arising from your failure to comply</li>
                    <li>You will provide Beba Mzigo with whatever proof of identity we may reasonably request</li>
                    <li>You will cooperate with Beba Mzigo and relevant authorities in all matters relating to the Services and</li>
                    <li>You will always act in compliance with applicable laws.</li>
                </ul>

                <p>By using the Services with respect to fulfillment services for your goods, you agree that:</p>
                <ul>
                    <li>You will only use the Services for lawful purposes; you will not use the Services for sending or storing any illegal or unlawful material or for fraudulent purposes</li>
                    <li>You are fully authorized to deal with the goods</li>
                    <li>You will not use the Services with respect to fulfillment of any cash, cash equivalents, jewellery, illegal, stolen or smuggled goods, counterfeit goods or undeclared hazardous goods and will indemnify Beba Mzigo against any liability arising from the carriage and storage of such goods</li>
                    <li>You will inform Beba Mzigo, in writing, if the goods are regulated by specific laws, licenses, guidelines or conditions, and will provide Beba Mzigo and the Transporters with specific and proper guidelines on the handling, storage and transportation of such goods, as relevant</li>
                    <li>You will not solicit or entice away the business or services of the Transporters from Beba Mzigo or the Services</li>
                    <li>You will only provide true and accurate information when using the Platform</li>
                    <li>You will not send or store infringing, obscene, threatening, libelous or otherwise unlawful or tortious material including material that violates third party rights</li>
                    <li>You will not try to harm the Transporters, their agents or the Services in any way whatsoever</li>
                    <li>You agree that Beba Mzigo will not be liable for any in-transit loss, damage, theft, destruction, or delayed delivery of any goods save as provided for under the Insurance clause.</li>
                </ul>

                <p>In the event of breach on your part Beba Mzigo reserves the right to immediately terminate the Agreement forthwith. Beba Mzigo further reserves the right to terminate the Agreement by written notice.</p>

                <h2>Insurance</h2>
                <p>In the event that the goods are established to have been lost/ damaged either in transit or in the warehouses or distribution hubs during Beba Mzigo’s provision of the Services and Beba Mzigo or the Transporter is found liable, an insurance claim may be raised as set out below.</p>
                <p>Beba Mzigo will obtain and keep in force during the Term insurance policies covering warehoused goods. Beba Mzigo shall require Transporters offering transport and logistics services seeking registration on its platforms, obtain and maintain insurance coverage against loss or damage for goods delivered in connection with the Services. Neither Beba Mzigo nor the Transporters will be liable for any damage before or after the goods are no longer in possession of Beba Mzigo or the Transporters.</p>
                <p>You shall immediately inform Beba Mzigo of any dispute arising in relation to a delivery no later than 3 days after delivery.</p>

                <h2>Intellectual Property Ownership</h2>
                <p>Beba Mzigo alone (and its licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights, in and to the Services and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by you or any other party relating to the Services. These Terms do not convey to you any rights of ownership in or related to the Services, or any intellectual property rights owned by Beba Mzigo. The Beba Mzigo name, Beba Mzigo logo, and the product names associated with the Services are trademarks of Beba Mzigo or third parties, and no right or license is granted to use them.</p>

                <h2>Third Party Interactions</h2>
                <p>The Services may be made available or accessed in connection with third party services and content (including advertising) that Beba Mzigo does not control. During use of the Services, you may, without limitation, enter into correspondence with, purchase goods and/or services from, or participate in promotions of third party providers, advertisers or sponsors. You acknowledge that different terms of use, warranties, representations and privacy policies may apply to your use of such third party services and content and in no event shall Beba Mzigo be responsible or liable for any representations, products or services of such third party providers. You acknowledge that your access and use of the Services in connection with third party services may be subject to terms set forth in the applicable third party’s terms and conditions. You acknowledge that the availability of the products, goods or services of third party providers on the Services is not an endorsement of the same by Beba Mzigo. You hereby further acknowledge that where third party providers of goods and/or services require your agreement to additional or separate terms and conditions prior to your use of or access to such goods or services, Beba Mzigo will have no responsibility nor liability arising from such agreements between you and the third party providers.</p>
                <p>Any third party interactions, and any terms, conditions, warranties or representations associated with such activity, are solely between you and the applicable third party. Beba Mzigo and its licensors shall have no liability, obligation or responsibility for any such correspondence, purchase, transaction or promotion between you and any such third party. Beba Mzigo does not endorse any sites on the Internet that are linked through the Services, and in no event shall Beba Mzigo or its licensors be responsible for any content, products, services or other materials on or available from such sites or third party providers.</p>
                <p>Beba Mzigo may rely on third party advertising and marketing supplied through the Services and other mechanisms to subsidize the Services. By agreeing to these Terms you agree to receive such advertising and marketing. Beba Mzigo may compile and release information regarding you and your use of the Services on an anonymous basis as part of a customer profile or similar report or analysis. You agree that it is your responsibility to take reasonable precautions in all actions and interactions with any third party you interact with through the Services.</p>

                <h2>Indemnification</h2>
                <p>You agree that you shall defend, indemnify and hold Beba Mzigo, its licensors and each such party's parent organizations, subsidiaries, affiliates, officers, directors, members, employees and agents harmless from and against any and all claims, costs, damages, demands, losses, liabilities and expenses (including legal fees and costs) arising out of or in connection with:</p>
                <ul style={{ listStyle: 'lower-alpha', textTransform: 'capitalize' }}>
                    <li>your violation or breach of any of these Terms or any applicable law or regulation, whether or not referenced herein</li>
                    <li>your violation of any rights of any third party, including providers of logistics or transport services arranged via the Services</li>
                    <li>your use or misuse of the Services or services or goods obtained through your use of the Services</li>
                    <li>Beba Mzigo’s use of your data and user content in accordance with these Terms or</li>
                    <li>the goods that are the subject of the Services.</li>
                </ul>

                <h2>DISCLAIMER OF WARRANTIES</h2>
                <p>THE SERVICES ARE PROVIDED TO YOU STRICTLY ON AN "AS IS" BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON‐INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED BY BEBA MZIGO TO THE EXTENT APPLICABLE UNDER LAW. BEBA MZIGO MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICES. BEBA MZIGO MAKES NO REPRESENTATIONS OR WARRANTIES THAT </p>
                <ul style={{ listStyle: 'upper-alpha' }}>
                    <li>THE USE OF THE SERVICES WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR‐FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA,</li>
                    <li>THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS,</li>
                    <li>ANY STORED DATA WILL BE ACCURATE OR RELIABLE,</li>
                    <li>THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS,</li>
                    <li>ERRORS OR DEFECTS IN THE SERVICES WILL BE CORRECTED, OR (F) THE SERVICES OR THE SERVER'S THAT MAKE THE SERVICES AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. BEBA MZIGO MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE AS TO THE RELIABILITY, SAFETY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF ANY SERVICES, PRODUCTS OR GOODS SUPPLIED BY THIRD PARTIES THROUGH THE USE OF THE SERVICES. BEBA MZIGO DOES NOT GUARANTEE THE SUITABILITY, LEGALITY, REGULATORY COMPLIANCE, QUALITY OR ABILITY OF A TRANSPORTER, USED THROUGH THE SERVICES. YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY THIRD PARTY SERVICES OR PRODUCTS REMAINS SOLELY WITH YOU.</li>
                </ul>

                <h2>LIMITATION OF LIABILITY</h2>
                <p>BEBA MZIGO AND/OR ITS LICENSORS SHALL NOT BE LIABLE TO ANYONE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING PERSONAL INJURY, PROPERTY DAMAGE, LOSS OF DATA, LOST REVENUE AND/OR PROFITS, LOSS OF USE OR OTHER ECONOMIC ADVANTAGE) RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY OF THE SERVICES, REGARDLESS OF THE NEGLIGENCE OF BEBA MZIGO, EVEN IF BEBA MZIGO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BEBA MZIGO AND/OR ITS LICENSORS SHALL NOT BE LIABLE FOR ANY LOSS, DAMAGE OR INJURY WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT LIMITED TO LOSS, DAMAGE OR INJURY ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THE SERVICES, INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE SERVICES, ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY THIRD PARTY PROVIDER, ADVERTISER OR SPONSOR WHOSE ADVERTISING APPEARS ON THE WEBSITE OR IS REFERRED BY THE SERVICES, EVEN IF  BEBA MZIGO AND/OR ITS LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                <p>BEBA MZIGO SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND BEBA MZIGO’S REASONABLE CONTROL. THE SERVICES MAY BE USED BY YOU TO CONNECT WITH THIRD PARTY PROVIDERS, BUT YOU AGREE THAT BEBA MZIGO HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY PRODUCTS, GOODS AND/OR SERVICES PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS. </p>
                <p>BEBA MZIGO WILL NOT BEAR ANY RESPONSIBILITY NOR INCUR ANY LIABILITY TO ASSESS THE SUITABILITY, LEGALITY OR ABILITY OF ANY TRANSPORTERS AND YOU EXPRESSLY WAIVE AND RELEASE BEBA MZIGO FROM ANY AND ALL LIABILITY, CLAIMS OR DAMAGES ARISING FROM OR IN ANY WAY RELATED TO THE TRANSPORTERS. BEBA MZIGO WILL NOT BE A PARTY TO DISPUTES, NEGOTIATIONS OF DISPUTES BETWEEN YOU AND SUCH TRANSPORTERS. RESPONSIBILITY FOR THE DECISIONS YOU MAKE REGARDING SERVICES OFFERED VIA THE SERVICES (WITH ALL ITS IMPLICATIONS) RESTS SOLELY WITH YOU. YOU EXPRESSLY WAIVE AND RELEASE ANY AND ALL RIGHTS AND BENEFITS UNDER THE LAWS OF THE REPUBLIC OF KENYA (OR ANY ANALOGOUS LAW OF ANY OTHER STATE).</p>
                <p>THE QUALITY OF THE LOGISTICS OR TRANSPORT SERVICES OBTAINED THROUGH THE USE OF THE SERVICES IS ENTIRELY THE RESPONSIBILITY OF TRANSPORTER WHO ULTIMATELY PROVIDES SUCH SERVICES TO YOU, AND YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU USE THE TRANSPORTER SERVICES ENTIRELY AT YOUR OWN RISK.</p>
                <p>EXCEPT AS EXPRESSLY STATED HEREIN, BEBA MZIGO WILL NOT BE LIABLE TO YOU FOR ANY CLAIMS ARISING OUT OF ANY LOGISTICS AND/OR TRANSPORT SERVICES PROCURED THROUGH THE SERVICES. IN ANY CASE, BEBA MZIGO’S AGGREGATE LIABILITY SHALL IN NO EVENT EXCEED THE AMOUNTS ACTUALLY PAID BY AND/OR DUE FROM YOU IN THE THREE (3) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM OF LIABILITY.</p>

                <h2>Internet Delays</h2>
                <p>The Services may be subject to limitations, delays, and other problems inherent in the use of the internet and electronic communication. Beba Mzigo is not responsible for any delays, delivery failures, or any other liability, costs or damage resulting from such problems.</p>

                <h2>Notice</h2>
                <p>Beba Mzigo may give notice by means of a general notice on the Services, electronic mail to your email address in your account, telephone or text message to any telephone number provided in connection with your account, or by a notification message displayed on your account page. Such notice shall be deemed to have been given upon publication on the Services or upon the expiration of 12 hours after sending (if sent by email or telephone). It shall be your responsibility to keep your contact information (including email address and telephone number) current and you will be deemed to have received any notice issued based on the contact information in your account, whether or not you actually receive it. You may give notice to Beba Mzigo, with such notice deemed given when received by Beba Mzigo, at any time through the email address <a href="#">support@bebamzigo.com</a></p>

                <h2>Data Privacy</h2>
                <p>Beba Mzigo’s collection and use of personal information in connection with the Services is described in our Data Privacy Policy located at <a href="#">https://www.bebamzigo.com/privacy</a> the terms of which are incorporated into these Terms. Any data, including personal data, shared with Beba Mzigo will be used in accordance with this Data Privacy Policy.</p>
                <p>You will ensure that any personal information that comes into your possession as a result of these Terms including but not limited to any data or information in regard to Beba Mzigo, Beba Mzigo’s employees, the Transporters and their employees and third parties and their employees must be collected and processed in accordance with the provisions of the Kenyan Data Protection Act, 2019 and any other applicable laws.</p>
                <p>By accessing or using the Services, you hereby freely, unequivocally and expressly authorize Beba Mzigo to receive, record, process, store and transfer your data, including personal data, in accordance with the law and our Data Privacy Policy.</p>
                <p>You hereby freely, unequivocally and expressly consent to the transfer of your data, including personal data, to third parties to be used for credit checks and other verification purposes.</p>

                <h2>Confidentiality</h2>
                <p>By accessing or using the Services, you hereby agree and undertake not to disclose to any person any confidential information concerning the business, affairs, customers, data, clients or suppliers of Beba Mzigo and the Transporters save as is expressly required by law. You also unequivocally agree not to use any information obtained through the Services for any purpose other than to procure fulfillment services through the Services. Any violation of this provision shall warrant a charge, for each such violation, that shall be determined by Beba Mzigo in its sole discretion, bearing in mind: </p>
                <ul>
                    <li>The loss and expense occasioned by such loss; and</li>
                    <li>Any deterrence measures Beba Mzigo may wish to pursue.</li>
                </ul>

                <h2>Publication of Information</h2>
                <p>If you provide Beba Mzigo any feedback, suggestions, or other information or ideas regarding the Service (“Feedback”), you hereby assign to Beba Mzigo all rights in the Feedback and agree that Beba Mzigo has the right to use such Feedback and related information in any manner it deems appropriate. Beba Mzigo will treat any Feedback you provide as non-confidential and non-proprietary. You agree that you will not submit to Beba Mzigo any information or ideas that you consider to be confidential or proprietary.</p>

                <h2>Non-Solicitation</h2>
                <p>You shall not, except with the prior written consent of Beba Mzigo, attempt to solicit or entice away; or solicit or entice away, from the service of Beba Mzigo, the services of any Transporter, for any duration of time however brief. This provision shall continue in force for one (1) year subsequent to termination of usage of the Services. In the event of a violation, you shall be liable to a penalty to be calculated as follows, for each person solicited or enticed away: the total gross revenue to Beba Mzigo, for the 12 months immediately preceding the solicitation event, supplied on average by a person of the same class as the Transporter.</p>

                <h2>Miscellaneous Provisions</h2>
                <ul>
                    <li><b>Termination.</b> In the event of breach on your part Beba Mzigo reserves the right to immediately terminate the Agreement forthwith, and/or close or suspend your Account. Beba Mzigo further reserves the right to terminate the Agreement by written notice.</li>
                    <li><b>Responsibility.</b> If you authorize, assist, encourage or facilitate another person or entity to take any action related to the subject matter of these Terms with Beba Mzigo, you shall be deemed to have taken the action yourself.</li>
                    <li><b>Severability.</b> If any portion of these Terms is held by a court of competent jurisdiction to be invalid or unenforceable, the remaining provisions of these Terms will remain in full force and effect, and any invalid or unenforceable portions shall be construed in a manner that most closely reflects the effect and intent of the original language. If such construction is not possible, the provision will be severed from these Terms, and the rest of the Terms shall remain in full force and effect.</li>
                    <li><b>Waivers.</b> Any failure by Beba Mzigo to enforce any right or provision of these Terms shall in no way be construed to be a present or future waiver of such provision nor in any way affect our right to enforce such provision thereafter. All waivers by us must be acknowledged and agreed in writing.</li>
                    <li><b>Successors and Assigns.</b> These Terms will be binding upon, and inure to the benefit of Beba Mzigo and any contracting parties and their respective successors and assigns.</li>
                    <li><b>Assignment.</b> You may not assign these Terms without the prior written approval of Beba Mzigo. Beba Mzigo may assign these Terms without your consent to:</li>
                    <ul>
                        <li>a parent, subsidiary or affiliate.</li>
                        <li>an acquirer Beba Mzigo’s equity, business or assets; or</li>
                        <li>a successor by merger. Any purported assignment in violation of this section shall be void.</li>
                    </ul>
                    <li><b>No Agency.</b> No partnership, contract of employment, agency, joint venture or franchise relationship shall be created between you, Beba Mzigo or any Transporters as a result of these Terms or use of the Services.</li>
                    <li><b>No Third Party Beneficiary.</b> You acknowledge and agree that, except as otherwise expressly provided in these Terms, there shall be no third party beneficiary to any agreement entered into with Beba Mzigo.</li>
                    <li><b>Choice of Law.</b> These Terms are governed by and construed in accordance with the laws of Kenya without giving effect to any conflict of law principles and Kenyan courts shall have exclusive jurisdiction over disputes under the Agreement.</li>
                </ul>

                <h3>Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section</h3>
            </div>

            <Footer />
        </>
    )
}


export default TermsOfServices;